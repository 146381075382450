import FormModel from "@/apps/core/models/formModel";
import { Pasien, PasienAPI } from "./pasien";
import { setRefreshRequest } from "../modules/stores/pasien";

export default class PasienFM extends FormModel {
  constructor(requiredFields: Array<string>, nonRequiredFields: Array<string>) {
    super(
      new PasienAPI(),
      new Pasien(),
      requiredFields,
      nonRequiredFields,
      setRefreshRequest
    );
  }

  /*
  Yang akan digunakan di vue single file component adalah:
  - instance
  - errorMap
  - validity.getIsValid() (computed)
  - setEdited (method)
  - validate (method)
  - save (method)
  */
}

// export class PasienModalFM extends PasienFM {
//   async save() {

//     const respData: Record<string, any> = await this.api.create(
//       this.getPayload()
//     );
//     // penanda error http400, adalah adanya field error
//     if ("error" in respData) {
//       delete respData.error;
//       this.updateErrorMap(respData);
//     } else {
//       // TODO: Perlu diperbaiki agar lebih konsisten!!!!!
//       // karena sekarang nama-nya ditambahkan norm secara manual.
//       const instance = this.instance as Pasien;
//       instance.id = respData.id;
//       instance.nama = `${instance.noRm} - ${instance.nama}`;
//     }
//   }
// }
